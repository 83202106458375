import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function CoachTaskPage() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                У адміністратора є можливість давати завдання тренерам та контролювати їх виконання.
            </Typography>
            <Typography>
                Приклади заваднь:
            </Typography>
            <ul>
                <li>Зробити фото для інстаграму</li>
                <li>Написати план занять</li>
            </ul>
            <Typography>
                Для доступу до завдань тренерам, будь-ласка, виконайте наступні дії:
            </Typography>
            <ol>
                <li>Натисніть на кнопку "⭐&nbsp;Тренери" на клавіатурі знизу екрана</li>
                <li>Натисніть на кнопку "📝&nbsp;Завдання тренерам"</li>
            </ol>
    </Card>


            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати завдання?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "✏️ Створити завдання"</li>
                        <li>Оберіть тренера, якому завдання буде поставлено</li>
                        <li>Натисніть на кнопку "📋 Нове завдання"</li>
                        <li>Напишіть опис завдання</li>
                        <li>Затвердити</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як переглянути всі завдання?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "⭐ Тренери"</li>
                        <li>Натисніть на кнопку "📝 Завдання тренерам"</li>
                    </ol>
                    <Typography>
                        Завдання можуть мати наступні статуси:
                    </Typography>
                    <ul>
                        <li>⏳ Не виконані - нові завдання від адміністратора</li>
                        <li>👌 Виконані - тренер виконав завдання</li>
                        <li>✅ Перевірені - адміністратор перевірив і затвердив виконання</li>
                        <li>👎🏻 Відхилені - адіністратор перевірив і повернув до виконання</li>
                    </ul>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Що робити тренеру, коли виконав завдання?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Треба натиснути на кнопку "👌 Виконано" (в боті для тренера)
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Що робити адміністратору, коли тренер виконав завдання?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Якщо завдання викнано вірно - треба натиснути на кнопку "✅ Перевірено"
                    </Typography>
                    <Typography>
                        Якщо завдання викнано не вірно - треба натиснути на кнопку "↩️ Повернути до виконання"
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}


export default CoachTaskPage;
