import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до інформації про певного адміністратора, будь-ласка, виконайте наступні дії:
            </Typography>
            <ol>
                <li>Натисніть на кнопку "🏠&nbsp;Про&nbsp;студію на клавіатурі знизу екрана</li>
                <li>Натисніть на кнопку "🧑&nbsp;Адміністратори"</li>
                <li>Натисніть на кнопку з іменем потрібного вам адміністратора</li>
            </ol>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ol>
                            <li>Натисніть на кнопку "🆕 Додати адміністратора"</li>
                            <li>Напишіть імʼя адміністратора</li>
                            <li>Отримайте кодове слово</li>
                            <li>Надішліть кодове слово адміністратору разом з посиланням на бізнес-бот https://t.me/ArenaSportBusinessBot</li>
                            <li>Готово!</li>
                        </ol>
                    </Typography>
                    <br />
                    <Typography>
                        Якщо адміністратора вже доданий до системи, але треба йому змінити телеграм акаунт - натисніть, будь-ласка, на кнопку "🔗 Відʼєднати від telegram".<br />
                        Тепер адміністратор зможе зайти з іншого телеграм аккаунту
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити імʼя?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Змінити імʼя"</li>
                        <li>Напишіть потрібне нове імʼя</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    ❌ Як видалити?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "❌ Видалити"</li>
                        <li>Підтвердіть вашу дію, так як діє є незворотною</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

    </>
    )
}


export default Page;
