import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до інформації про певну адресу, будь-ласка, виконайте наступні дії:
            </Typography>
            <ol>
                <li>Натисніть на кнопку "🏠&nbsp;Про&nbsp;студію на клавіатурі знизу екрана</li>
                <li>Натисніть на кнопку "📍" потрібної адреси</li>
            </ol>

            <Typography>
                Кожна адреса може мати декілька зон.
            </Typography>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати адресу?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ol>
                            <li>Натисніть на кнопку "📍 Додати адресу"</li>
                            <li>Напишіть назву адреси</li>
                            <li>Готово!</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати зону до адреси?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ol>
                            <li>Натисніть на кнопку "📍" потрібної адреси в меню студії</li>
                            <li>Натисніть на кнопку "🏫 Додати нову зону"</li>
                            <li>Напишіть назву зони</li>
                            <li>Готово!</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити назву?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Назва"</li>
                        <li>Напишіть потрібне нову назву</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити опис?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Опис"</li>
                        <li>Напишіть потрібне новий опис</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити місто?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Місто"</li>
                        <li>Напишіть потрібне нове місто</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити адресу?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Адреса"</li>
                        <li>Напишіть потрібне нову адресу</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити координати?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Координати"</li>
                        <li>Напишіть потрібні координати</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    ❌ Як видалити?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "❌ Видалити"</li>
                        <li>Підтвердіть вашу дію, так як діє є незворотною</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

    </>
    )
}


export default Page;
