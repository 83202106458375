import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню розсилки повідомлень, будь-ласка, натисніть на кнопку "💬&nbsp;Повідомлення" на клавіатурі знизу екрана
            </Typography>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як написати повідомлення декільком клієнтам?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Клієнтам"</li>
                        <li>Оберіть в меню тип отримувача</li>
                        <li>Напишіть повідомлення</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як написати повідомлення декільком тренерам?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Тренерам"</li>
                        <li>Оберіть в меню тип отримувача</li>
                        <li>Напишіть повідомлення</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

    </>
    )
}


export default Page;
