import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню послуг, будь-ласка, натисніть на кнопку "🌸 Послуги" на клавіатурі знизу екрана
            </Typography>
            <br />
            <Typography>
                Послуги можуть бути обʼєднані в групи або існувати окремо
            </Typography>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати нову послугу?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Якщо послуга не належить до будь-якої группи, то:
                    </Typography>
                    <ol>
                        <li>Натисніть на кнопку "🏷️ Додати послугу"</li>
                        <li>Напишіть назву послуги</li>
                        <li>Готово!</li>
                    </ol>

                    <Typography>
                        Якщо послуги треба обʼєднати в групу:
                    </Typography>
                    <ol>
                        <li>Натисніть на кнопку "📂 Додати групу послуг"</li>
                        <li>Напишіть назву групи</li>
                        <li>Натисніть на кнопку "🏷️ Додати послугу"</li>
                        <li>Напишіть назву послуги</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити назву?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну послугу</li>
                        <li>Натисніть на кнопку "Змінити назву"</li>
                        <li>Напишіть потрібну нову назву</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити опис?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну послугу</li>
                        <li>Натисніть на кнопку "Змінити опис"</li>
                        <li>Напишіть потрібний новий опис</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    📷 Як змінити фото?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну послугу</li>
                        <li>Натисніть на кнопку "Змінити фото"</li>
                        <li>Надішліть нове фото</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    ❌ Як видалити?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну послугу</li>
                        <li>Натисніть на кнопку "❌ Видалити"</li>
                        <li>Підтвердіть вашу дію, так як діє є незворотною</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
    </>
    )
}


export default Page;
