import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню розсилки опитувань, будь-ласка, натисніть на кнопку "👫🏻&nbsp;Клієнти" на клавіатурі знизу екрана, а потім обрати "📊&nbsp;Опитування" 
            </Typography>
            <br />
            <Typography>
                Ви маєте можливість створювати опитування для своїх клєнтів, щоб дізнаватись їх думку щодо різноманітних подій.
            </Typography>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як створити опитування?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Створити нове"</li>
                        <li>Оберіть в меню тип отримувача</li>
                        <li>Напишіть назву опитування то можливі варіанти відповідей</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як дізнатись результати?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть тип опитування</li>
                        <li>Оберіть потрібне опитування</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>

    </>
    )
}


export default Page;
