import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function CoachPage() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до інформації про певного тренера, будь-ласка, виконайте наступні дії:
            </Typography>
            <ol>
                <li>Натисніть на кнопку "⭐&nbsp;Тренери" на клавіатурі знизу екрана</li>
                <li>Натисніть на кнопку з іменем потрібного вам тренера</li>
            </ol>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як підключити тренера до системи?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Якщо тренер ще не доданий до системи, то для того, щоб його підключити треба надіслати йому посилання, яке ви бачите при перегляді його інформації<br />
                        <b>Приклад:</b><br />
                        Надішліть це посилання тренеру, для додання в @ArenaSportCoachBot
                        https://t.me/ArenaSportCoachBot?start=coach-join-00000000
                        (замість 00000000 ви побачите інші цифри)
                        <br />
                        Треба дане посилання скопіювати та надіслати тренеру
                    </Typography>
                    <br />
                    <Typography>
                        Якщо тренер вже доданий до системи, але треба йому змінити телеграм акаунт - натисніть, будь-ласка, на кнопку "🔗 Доєднати до іншого акаунту".<br />
                        А потім повторіть алгоритм вище, щодо додання тренера до системи.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    💶 Заробітна плата
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Існують три варіанти задання заробітної плати для тренера
                    </Typography>
                    <ol>
                        <li>Зарплата за замовчуванням, на всі його тренування</li>
                        <li><Link to="/service#salary">Зарплата за певну послугу</Link></li>
                        <li><Link to="/calendar#salary">Зарплата за певне тренування</Link></li>
                    </ol>
                    <Typography>
                        При розрахунку заробітної плати за кожне тренування, користуємось наступним алгоритмом
                    </Typography>
                    <ol>
                        <li>Якщо є задана окрема зарплата за тренування - враховуємо її</li>
                        <li>Якщо є задана окрема зарплата за послугу - враховуємо її</li>
                        <li>У всіх інших випадках - використувоуємо значення, що задане для самого тренера</li>
                    </ol>
                    <Typography>
                        Щоб змінити основне значення зарплати для тренера, треба:
                    </Typography>
                    <ol>
                        <li>Натисніть на кнопку "Змінити зарплатню"</li>
                        <li>Напишіть потрібне значення</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити імʼя?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Змінити імʼя"</li>
                        <li>Напишіть потрібне нове імʼя</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити опис?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Змінити опис"</li>
                        <li>Напишіть потрібний новий опис</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    📷 Як змінити фото?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Змінити фото"</li>
                        <li>Надішліть нове фото</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    ❌ Як видалити тренера?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "❌ Видалити"</li>
                        <li>Підтвердіть вашу дію, так як діє є незворотною</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    💬 Як надіслати повідомлення?
                </AccordionSummary>
                <AccordionDetails>
                    У системі є можливість спілкування з тренерами в боті. Для того, щоб відправити повідомлення тренеру треба
                    <ol>
                        <li>Натисніть на кнопку "💬 Повідомлення"</li>
                        <li>Написати повідомлення</li>
                        <li>Підтвердити дію</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
    </>
    )
}


export default CoachPage;
