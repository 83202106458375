import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню налаштувань студії, будь-ласка, натисніть на кнопку "🏠&nbsp;Про&nbsp;студію" на клавіатурі знизу екрана
            </Typography>
    </Card>

        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                Як змінити назву?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Назву"</li>
                    <li>Напишіть потрібне нову назву</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                Як змінити опис?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Опис"</li>
                    <li>Напишіть потрібний новий опис</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                📷 Як змінити лого?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Лого"</li>
                    <li>Надішліть нове фото</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                Як змінити платіжні реквізити?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Реквізити"</li>
                    <li>Напишіть потрібні реквізити</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                Як змінити посилання в Instagram?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Instagram"</li>
                    <li>Напишіть потрібне посилання</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                Як змінити Monobank API ключ?
            </AccordionSummary>
            <AccordionDetails>
                <ol>
                    <li>Натисніть на кнопку "Monobank"</li>
                    <li>Напишіть потрібний API ключ, що ви отримали від монобанку</li>
                    <li>Готово!</li>
                </ol>
            </AccordionDetails>
        </Accordion>
    </>
    )
}


export default Page;
