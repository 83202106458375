import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню календаря, будь-ласка, натисніть на кнопку "🗓️&nbsp;Календар" на клавіатурі знизу екрана
            </Typography>
            <br />
            <Typography>
                За регулярністю тренування поділяються на:
            </Typography>
            <ul style={{listStyleType: 'none'}}>
                <li>🔁 - регулярне заняття</li>
                <li>1️⃣ - заняття лише на вказану дату</li>
            </ul>
            <Typography>
                За кількістю клієнтів поділяються на:
            </Typography>
            <ul style={{listStyleType: 'none'}}>
                <li>👫 - групове заняття</li>
                <li>🧍‍♀️ - індивідуальне заняття</li>
            </ul>
            <Typography>
                Для переходу між днями тижня - треба натиснути на кнопку відповідного дня ("Пн", "Вт", і т.д.). <br />
                Обраний день тижня позначений символом ✅
            </Typography>
            <br />
            <Typography>
                Для того, щоб перейти на попередній або наступний тиждень - треба скористатись кнопками з відповідними датами та символаи ⬅️ і ➡️
            </Typography>
        </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як створити групове заняття?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть, на кнопку "👫 +Групове"</li>
                        <li>Оберіть тип "🔁 Дні тижня" (регулярне) або "1️⃣ Дата" (разове)</li>
                        <li>Готово!</li>
                        <li>Тепер можна налаштувати його параметри (див. пункт нижче)</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як створити індивідуальне заняття?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть, на кнопку "🧍 +Індивідуальне"</li>
                        <li>Оберіть тип "🔁 Дні тижня" (регулярне) або "1️⃣ Дата" (разове)</li>
                        <li>Готово!</li>
                        <li>Тепер можна налаштувати його параметри (див. пункт нижче)</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як налаштувати дні тижня регулярного заняття?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть, будь-ласка, в календарі на кнопку вашого заняття</li>
                        <li>Натискаючі на кнопки з назвами днів тижня - ви можете змінити регулярніть ваших тренувань</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
    </>
    )
}


export default Page;
