import * as React from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function Page() {


    return (
      <>
        <Card style={{padding: '0px 10px'}}>
            <Typography>
                Для доступу до меню абонементів, будь-ласка, натисніть на кнопку "🌟&nbsp;Абонементи" на клавіатурі знизу екрана
            </Typography>
            <br />
            <Typography>
                Абонементи обʼєднуються в групи
            </Typography>
    </Card>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати нову групу абонементів?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Натисніть на кнопку "Додати групу абонементівг"</li>
                        <li>Напишіть назву групи</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати новий абонемент?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Натисніть на кнопку "🆕 Додати абонемент"</li>
                        <li>Напишіть назву абонементу</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як додати послугу до абонементу?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Кожна група абонементів має перелік повʼязаних послуг, що можуть бути сплачені цим абонементом
                    </Typography>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Натисніть на кнопку "Обрати послуги для абонементу"</li>
                        <li>Натисніть на кнопку з навою відповідної послуги</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити тип (груповий/індивідуальний)?
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Кожен абонемент належить до певного типу: груповий або індивідуальний. <br />
                        Для того, щоб змінити тип, треба
                    </Typography>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Натисніть на кнопку "Змінити тип"</li>
                        <li>Натисніть на кнопку з відповідним типом</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити назву групи?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Натисніть на кнопку "Змінити назву"</li>
                        <li>Напишіть потрібний нову назву</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити ціну абонементу?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Оберіть потрібний абонемент</li>
                        <li>Натисніть на кнопку "Ціна"</li>
                        <li>Напишіть потрібний нову ціну</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити кількість відвідувань абонементу?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Оберіть потрібний абонемент</li>
                        <li>Натисніть на кнопку "Кількість"</li>
                        <li>Напишіть потрібний нову кількість</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити назву абонементу?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Оберіть потрібний абонемент</li>
                        <li>Натисніть на кнопку "Назва"</li>
                        <li>Напишіть потрібний нову назву</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити строк дії абонементу?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Оберіть потрібний абонемент</li>
                        <li>Натисніть на кнопку "Строк дії"</li>
                        <li>Напишіть потрібний строк дії</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{color: '#1149e0'}}>
                    Як змінити кількість заморожувань?
                </AccordionSummary>
                <AccordionDetails>
                    <ol>
                        <li>Оберіть потрібну групу абонементів</li>
                        <li>Оберіть потрібний абонемент</li>
                        <li>Натисніть на кнопку "Заморожування"</li>
                        <li>Напишіть потрібний строк дії</li>
                        <li>Готово!</li>
                    </ol>
                </AccordionDetails>
            </Accordion>
    </>
    )
}


export default Page;
