import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    Typography, Box, IconButton, Button,
    Card, CardHeader, CardContent, List, ListItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

export default function MainMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
        >
            <MenuIcon />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
        sx: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', // 2 колонки
          gap: '8px', // Расстояние между колонками
        },
      }}
        >

            <MenuItem onClick={handleClose} component={Link} to="/">Головна</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/subscription">🌟 Абонементи</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/service">🌸 Послуги</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/coach">⭐ Тренери</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/coach-task">📋 Завдання</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/calendar">🗓️ Календар</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/client">👫🏻 Клієнти</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/message">💬 Повідомлення</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/finance">💰 Фінанси</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/poll">📊 Опитування</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/studio">🏠 Про студію</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/address">📍 Адреси</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/admin">🧑 Адміністратори</MenuItem>
        </Menu>
      </div>
    );
}
