import * as React from 'react';
import {
    AppBar, Toolbar, Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import MainMenu from './Menu'

function AppToolbar() {

    const [title, setTitle] = React.useState(null);

    const location = useLocation();
      React.useEffect(() => {
        switch (location.pathname) {
          case '/':
            setTitle('Інструкція з використання')
            break;
          case '/coach': setTitle('⭐ Тренери'); break;
          case '/coach-task': setTitle('📝 Завдання тренерам'); break;
          case '/service': setTitle('🌸 Послуги'); break;
          case '/client': setTitle('👫🏻 Клієнти'); break;
          case '/calendar': setTitle('🗓️ Календар'); break;
          case '/message': setTitle('💬 Повідомлення'); break;
          case '/studio': setTitle('🏠 Про студію'); break;
          case '/admin': setTitle('🧑 Адміністратори'); break;
          case '/address': setTitle('📍 Адреси'); break;
          case '/poll': setTitle('📊 Опитування'); break;
          case '/subscription': setTitle('🌟 Абонементи'); break;
          default:
            setTitle('Інструкція з використання')
        }

        if(location.pathname.includes('report/coach')){
            setTitle('Звіт');
        }
      }, [location.pathname]);

    return (
        <AppBar position="static" color="error">
            <Toolbar>
                <MainMenu />

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{title}</Typography>
            </Toolbar>
        </AppBar>
    );
}

export default AppToolbar;
